<script setup lang="ts">
import { getAppByDeveloperApi } from '~/api'
import type { ReturnPromiseType } from '~/types/base'

const route = useRoute()

const developer = computed(() => route.params.name as string)
const pageKey = computed(() => `developer-${developer.value}`)

const { value, addToCache } = await useDataCache<ReturnPromiseType<typeof getAppByDeveloperApi>>(unref(pageKey))
const { data, status, error } = await useAsyncData(unref(pageKey), async () => {
  if (value) {
    return value
  }

  const response = await getAppByDeveloperApi(developer.value)
  addToCache(response)
  return response
}, {
  getCachedData: (key, nuxt): ReturnPromiseType<typeof getAppByDeveloperApi> => nuxt.payload.data[key] || nuxt.static.data[key],
  lazy: import.meta.client,
})
whenever(error, () => {
  useRouteCache(route => route.setUncacheable())
  throw createError({ statusCode: 404, statusMessage: `${unref(pageKey)} has some error`, data: { name: developer.value }, fatal: true })
}, { immediate: true })

if (!isProd()) {
  whenever(data, v => clientLog.log(unref(pageKey), v), { immediate: true })
}

const { toAppDetail } = useRouterToPage()

const { autoSideBar } = useLayout()
autoSideBar()

const { developerHead } = usePageHead()
developerHead(developer, data)

useCacheRoute()
</script>

<template>
  <div v-track="{ id: 'web-developers', view: { value: { page: 'developers-name', seo: $route.params.name } } }" class="view-content" pb="80px md:160px">
    <h1 class="text-center c-#161636 font-900" lt-md="mt-40px text-20px" md="mt-60px text-32px">
      {{ $t('developer.title', { developer }) }}
    </h1>
    <h2 class="text-center c-#161636 c-op-70 font-400" lt-md="mt-16px text-14px lh-20px" md="mt-20px text-16px lh-24px">
      {{ $t('developer.desc', { developer }) }}
    </h2>

    <div
      class="grid"
      lt-md="grid-cols-4 gap-[20px_12px] mt-40px"
      md="grid-cols-8 gap-[24px_40px] mt-60px"
    >
      <template v-if="status === 'pending'">
        <GameLiteBlockSkeleton v-for="i in 32" :key="i" />
      </template>
      <template v-else>
        <template v-if="!data?.length">
          <div class="grid-col-[span_8] flex-center flex-col">
            <Icon class="aspect-ratio-32/17 h-auto max-w-320px w-53.3vw" name="SearchNone" />
            <div class="mx-auto mt-20px max-w-580px">
              <p class="text-center c-#161636 c-op-50" lt-md="text-14px lh-20px" md="text-16px lh-24px">
                {{ $t('error.noData') }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="item in data" :key="item.gameId">
            <GameLiteBlock
              v-track="{ id: 'web-developers-game', click: { value: { type: developer, name: item.gameName, seo: item.seo, pkg: item.packageName } } }"
              mode="light" :name="item.gameName" :icon="item.icon"
              :to="toAppDetail({ seo: item.seo, type: item.category }, { hasReturnUrl: true })"
            />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
