<script setup lang="ts">
import type { StoreApp, StoreAppMenu } from '~/types/store'

interface Props {
  /** 配置位 */
  value: StoreAppMenu
  /** 广告位 */
  banner: StoreAppMenu
  /** 广告地区 */
  countryCode: string
}
const props = defineProps<Props>()

// 广告拼在配置前面
const totalList = computed(() => [...(props.banner?.list || []), ...(props.value?.list || [])])

const loopMode = computed(() => totalList.value.length >= 7)
const swiperInstance = ref()
const swiperAttr = computed<Record<string, any>>(() => ({
  modules: [SwiperAutoplay, SwiperNavigation],
  autoplay: { delay: 4000, disableOnInteraction: false },
  navigation: {
    disabledClass: 'pointer-events-none c-op-30',
    lockClass: 'hidden',
    prevEl: '.prev',
    nextEl: '.next',
  },
  direction: 'vertical',
  centeredSlides: true,
  loop: loopMode.value,
  centerInsufficientSlides: !loopMode.value,
  centeredSlidesBounds: !loopMode.value,
  slidesPerView: 'auto',
  spaceBetween: 20,
}))

// Math.floor((props.value?.list?.length || 0) / 2)
const activeInx = ref(0)
const activeGame = computed(() => totalList.value[activeInx.value])

// 首次居中
function initSwiper(swiper: any) {
  swiperInstance.value = swiper
  swiperInstance.value.slideToLoop(activeInx.value)
}

// 这边是优先拿 banner
function getBannerImg(app: StoreApp) {
  let img: string = ''
  if (app.marks.adsMark) {
    img = app.adsInfo.banner
  }
  if (!img) {
    img = app.appInfo.imgUrl || app.appInfo.banner || app.appInfo.imgUrls?.[0] || ''
  }

  return scaleImage(img, [1440, 0])
}

/** 减少首屏加载数量 */
function canLoadImg(i: number) {
  return activeInx.value - 2 <= i && activeInx.value + 2 >= i
}
</script>

<template>
  <div v-if="totalList.length" class="aspect-ratio-1300/452 w-full flex" lt-md="mt-20px" md="mt-40px gap-16px">
    <div class="relative h-full w-72px flex items-center" lt-md="of-hidden size-0">
      <div class="size-full py-32px">
        <ClientOnly>
          <Swiper class="h-full position-initial!" v-bind="swiperAttr" @swiper="initSwiper" @real-index-change="activeInx = $event?.realIndex">
            <SwiperSlide v-for="(item, inx) in totalList" :key="item.appInfo.id" class="h-52px! w-72px!">
              <div class="relative size-full flex items-center">
                <div class="size-full" />
                <div
                  class="absolute top-50% translate-y--50% of-hidden rd-10px transition-all-300" b="3px solid"
                  :class="activeInx === inx ? 'size-72px b-purple-5' : 'size-58px b-transparent ml--3px'"
                  @click="swiperInstance?.slideToLoop(inx)"
                >
                  <LdImage class="size-full" :src="scaleImage(item.iconUrl, 128)" :alt="item.name" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>

            <LdSwiperSlideButton v-if="loopMode" class="prev inset-bs-0 w-72% flex-center text-16px left-0! translate-x-0!" turn="top" bg="#fff hover:purple-5" c="purple-5 hover:#fff" @click="swiperInstance?.slidePrev()" />
            <LdSwiperSlideButton v-if="loopMode" class="next inset-be-0 w-72% flex-center text-16px left-0! translate-x-0!" turn="bottom" bg="#fff hover:purple-5" c="purple-5 hover:#fff" @click="swiperInstance?.slideNext()" />
          </Swiper>
        </ClientOnly>
      </div>
    </div>

    <div class="relative size-full flex-1 of-hidden rd-8px transition-all-300">
      <div class="pointer-events-none absolute bottom-0 left-0 z-2 h-50% w-full bg-gradient-to-t" from="purple-5 op-85" to="transparent" />

      <ClientOnly>
        <div class="absolute z-4 lt-md:hidden" inset="be-80px ie-80px">
          <NuxtLink v-track="{ id: 'web-apps-game', click: { value: { type: '大轮播', name: activeGame.name, seo: activeGame.appInfo.seoUrl, pkg: activeGame.packageName } } }" class="ld-button h-48px min-w-120px rd-8px text-16px font-700 ld-button-yellow" v-bind="getStoreAppLink(activeGame, countryCode)">
            {{ $t('layout.download') }}
          </NuxtLink>
        </div>
      </ClientOnly>

      <template v-for="(item, i) in totalList" :key="item.id">
        <NuxtLink
          v-track="{ id: 'web-apps-game', click: { value: { type: '大轮播', name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }"
          class="absolute-center size-full transition-all-300"
          :class="activeGame === item ? 'op-100 z-1' : 'op-0 z--1'"
          v-bind="getStoreAppLink(item, countryCode)"
        >
          <LdImage v-if="canLoadImg(i)" class="size-full object-cover" :src="getBannerImg(item)" alt="" :loading="i === 0 ? 'eager' : 'lazy'" />
        </NuxtLink>

        <div
          class="pointer-events-none absolute bottom-0 z-3 w-full flex object-cover transition-all-300"
          p="x-16px y-10px md:40px"
          :class="activeGame === item ? 'op-100' : 'op-0'"
        >
          <div class="block size-full flex-1">
            <LdImage class="rd-8px" size="52px md:100px" :src="scaleImage(item.iconUrl, 128)" :alt="item.name" loading="lazy" />
            <p class="line-clamp-1 c-#fff font-800" lt-md="mt-4px text-16px lh-20px" md="mt-16px text-32px lh-40px">
              {{ item.name }}
            </p>
            <div class="line-clamp-1 c-#fff c-op-90" lt-md="mt-4px text-12px lh-16px" md="mt-18px text-16px lh-20px">
              <LdRateLite class="gap-8px svg:v-base" :value="item.appInfo.rating || 4" />
              <template v-if="item.appInfo.appSimpleDesc">
                <span class="c-#fff c-op-30" mx="12px md:16px">|</span>
                <span>{{ item.appInfo.appSimpleDesc }}</span>
              </template>
            </div>
          </div>

          <!-- fakebutton -->
          <div class="ld-button mx-40px h-48px min-w-120px rd-8px text-16px font-700 op-0 lt-md:hidden">
            {{ $t('layout.download') }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
