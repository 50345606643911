<script setup lang="ts">
import type { StoreApp, StoreAppMenu } from '~/types/store'

interface Props {
  group: StoreAppMenu
  /** 广告地区 */
  countryCode: string
  /** 上报 */
  trackType: string
}

defineProps<Props>()

const swiperInstance = ref()
const swiperAttr = reactive<Record<string, any>>({
  modules: [SwiperNavigation],
  navigation: {
    disabledClass: 'pointer-events-none c-op-30',
    lockClass: 'hidden',
    prevEl: '.prev',
    nextEl: '.next',
  },
  loop: true,
  slidesPerView: 1.4,
  slidesPerGroup: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 16,
    },
    1280: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 16,
    },
    1536: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 40,
    },
  },
})

// 这边是优先拿 imageUrl
function getBannerImg(app: StoreApp) {
  let img: string = ''
  if (app.marks.adsMark) {
    img = app.adsInfo.imageUrl
  }
  if (!img) {
    img = app.appInfo.imgUrl || app.appInfo.banner || app.appInfo.imgUrls?.[0] || ''
  }

  return scaleImage(img, [512, 0])
}
</script>

<template>
  <div class="relative">
    <Swiper
      m="y--10px! x--20px!" p="y-10px! x-20px!"
      :class="!swiperInstance && '[&>.swiper-wrapper]:grid [&>.swiper-wrapper]:grid-auto-rows-0 [&>.swiper-wrapper]:grid-rows-1 [&>.swiper-wrapper]:of-hidden [&>.swiper-wrapper]:grid-cols-2 [&>.swiper-wrapper]:gap-[0_16px] [&>.swiper-wrapper]:lg:grid-cols-3 [&>.swiper-wrapper]:lg:gap-[0_40px]'"
      v-bind="swiperAttr" @swiper="swiperInstance = $event"
    >
      <SwiperSlide v-for="item in (group?.list || [])" :key="item.appInfo.id">
        <div class="group relative h-full flex flex-col of-hidden rd-8px transition-all-300" md:hover="translate-y--10px">
          <NuxtLink v-track="{ id: 'web-apps-game', click: { value: { type: trackType, name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" class="absolute size-full" v-bind="getStoreAppLink(item, countryCode)" />
          <div class="pointer-events-none relative aspect-ratio-16/9 w-full of-hidden">
            <LdImage class="aspect-ratio-16/9 h-full object-cover" :src="getBannerImg(item)" alt="" loading="lazy" />
            <div class="absolute bottom-0 left-0 h-50% w-full translate-y-8px bg-gradient-to-t op-0 transition-all-300" from="purple-5 op-80" to="transparent" md:group-hover="op-80 translate-y-0px" />
          </div>

          <div class="flex bg-#fff" gap="12px md:16px" p="b-2px x-12px md:x-20px">
            <div
              class="flex-shrink-0 of-hidden rd-10px bg-#fff p-4px"
              md="size-80px translate-y--12px" lt-md="size-60px translate-y--8px"
            >
              <LdImage class="size-full rd-8px" loading="lazy" :alt="item.name" :src="scaleImage(item.iconUrl, 128)" />
            </div>

            <div>
              <p class="line-clamp-1 c-#161636 font-700" lt-md="text-14px lh-18px mb-6px mt-4px" md="text-18px lh-24px my-8px">
                {{ item.name }}
              </p>

              <GameTypePill v-if="item.appInfo.appType" class="relative" :name="item.appInfo.desc" :type="item.appInfo.appType" />
            </div>
          </div>
        </div>
      </SwiperSlide>

      <LdSwiperSlideButton class="prev inset-is-0 text-24px lt-md:hidden" turn="left" bg="#000 op-40" c="#fff hover:purple-5" @click="swiperInstance?.slidePrev()" />
      <LdSwiperSlideButton class="next inset-ie-0 text-24px lt-md:hidden" turn="right" bg="#000 op-40" c="#fff hover:purple-5" @click="swiperInstance?.slideNext()" />
    </Swiper>
  </div>
</template>

<style lang="scss" scoped>
</style>
