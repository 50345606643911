import type { SupportLocale } from '~/config/i18n'
import type { StoreApp } from '~/types/store'

/** 广告地区对应的语言 */
export function getLocaleByAdArea(country: string = 'en') {
  const areaMap: Record<string, SupportLocale> = {
    tw: 'tw',
    hk: 'tw',
    mo: 'tw',
    kr: 'kr',
    jp: 'jp',
    vn: 'vn',
    th: 'th',
    id: 'id',
    fr: 'fr',
    de: 'de',
    br: 'pt',
    ar: 'es',
    pe: 'es',
    es: 'es',
    mx: 'es',
    co: 'es',
    ec: 'es',
    cl: 'es',
    bo: 'es',
    do: 'es',
    ru: 'ru',
    ua: 'ru',
    by: 'ru',
    kz: 'ru',
    uz: 'ru',
  }
  return areaMap[country] || 'en'
}

/** 处理广告链接参数 */
export function replaceAdUrl(url: string, id: number, clickId: string) {
  const newUrl = (url || '')
    .replace('ldp_adid', `${id}`) // 替换成广告ID
    .replace('ldp_position', 'ldplayerx') // 助手资源位，统一为：ldplayerx
    .replace('{click_id}', clickId)

  // clientLog.log('替换参数后的广告链接: ', newUrl)
  return newUrl
}

/**
 * 处理应用跳转的链接逻辑
 * 因为目前广告那边是根据域名去查游戏详情，所以出现的游戏都是在当前语言能查询到的，跳的都是当前语言的游戏详情
 */
export function getStoreAppLink(app: StoreApp, _countryCode: string | undefined): Partial<Record<'to' | 'target', string>> {
  const { toAppDetail } = useRouterToPage()

  const { adsInfo, appInfo, marks } = app
  const type = appInfo.glCategory
  const seo = appInfo.seoUrl
  if (marks.adsMark) {
    // 上报时间 clickId
    const clickId = new Date().getTime().toString()
    if (adsInfo.actionType === '1') {
      const to = replaceAdUrl(adsInfo.clickUrl, adsInfo.offerId, clickId)
      return { to, target: '_blank' }
    }
    else {
      // const locale = getLocaleByAdArea(countryCode)
      const to = toAppDetail({ type, seo }, { hasReturnUrl: true })
      return { to }
    }
  }
  else {
    const to = toAppDetail({ type, seo }, { hasReturnUrl: true })
    return { to }
  }
}

/** 处理广告下载链接 */
// export function getStoreAppDownloadLink(app: StoreApp, countryCode?: string): string {
//   const { getXLink } = useDownload()

//   const { adsInfo, packageName, marks } = app
//   if (marks.adsMark) {
//     // 上报时间 clickId
//     const clickId = new Date().getTime().toString()
//     if (adsInfo.actionType === '1') {
//       return replaceAdUrl(adsInfo.clickUrl, adsInfo.offerId, clickId)
//     }
//     else {
//       const locale = getLocaleByAdArea(countryCode)
//       return getXLink({ pkg: packageName, from: locale }).value
//     }
//   }

//   return getXLink({ pkg: packageName }).value
// }
