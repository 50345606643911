import type { FetchOptions } from 'ofetch'
import { parseURL } from 'ufo'
import { createHttp } from './basic'

export const useStoreHttp = createHttp({
  // isErrorCode: code => code !== 0,
  isErrorCode: code => code !== 200,

  handleErrorCode: (code) => {
    clientLog.error('useStoreHttp errcode', code)
  },

  handleFetchOptions: (opts, headers) => {
    const { $i18n: { locale } } = useNuxtApp()
    const { public: { apiStore } } = useRuntimeConfig()

    const baseURL = apiStore.replaceAll('{locale}', opts.requestLocale || locale.value)

    if (import.meta.server) {
      headers.host = parseURL(baseURL).host!
    }

    return {
      baseURL,
      headers,
    } as FetchOptions
  },
})
