<script setup lang="ts">
import { getAppCenterApi } from '~/api/store'
import type { ReturnPromiseType } from '~/types/base'
import type { StoreHome } from '~/types/store'

// definePageMeta({
//   // 首页特殊处理透明
//   middleware: () => {
//   },
// })
const { setConf } = useLayout()

setConf({
  footBar: {
    visible: true,
    fixed: true,
  },
})

// const { setLanguage, languageLoading } = useLanguageStore()
// const titleMap: Partial<Record<SupportLocale, string>> = {
//   en: 'Play Best Android Games on PC with LDPlayer X',
//   tw: '在電腦上用LDPlayer X玩最好的Android遊戲',
//   kr: 'PC에서 LDPlayer X로 최고의 안드로이드 게임을 플레이하세요',
//   vn: 'Chơi game Android tốt nhất cùng LDPlayer X trên máy tính',
//   th: 'เล่นเกม Android ที่ดีที่สุดบน PC ด้วย LDPlayer X',
//   ru: 'Играть в лучшие игры для Android на ПК с LDPlayer X',
//   pt: 'Jogue os melhores jogos Android no PC com LDPlayer X',
//   es: 'Juega los mejores juegos de Android en tu PC con LDPlayer X',
//   id: 'Mainkan game Android terbaik di PC dengan LDPlayer X',
//   jp: 'PCでベストなAndroidゲームをLDPlayer Xでプレイ',
//   fr: 'Jouez aux meilleurs jeux Android sur PC avec LDPlayer X',
//   de: 'Spielen Sie die besten Android-Spiele auf dem PC mit LDPlayer X',
//   ar: '" العب أفضل ألعاب Android على جهاز الكمبيوتر مع LDPlayer X',
// }
// const pathMap = Object.fromEntries(Object.keys(titleMap).map(locale => [locale, '/']))
// setLanguage(pathMap, titleMap)
// languageLoading.resolve()

const pageKey = computed(() => 'app-center')
const { value: countryMapCache, addToCache: addCountryCache } = await useDataCache<Record<string, StoreHome>>(unref(pageKey))
const { data, error } = await useAsyncData(unref(pageKey), async () => {
  // 每个地区缓存的内容
  const headers = useRequestHeaders()

  if (!isDev() && headers?.['x-forwarded-for']) {
    // 用户ip有可能会有多个 取第0个 eg: '46.232.120.205, 64.252.87.132'
    const userIp = headers?.['x-forwarded-for'].split(',')[0]

    const userCountry = await $fetch('/api/get-ip-cache', { query: { ip: userIp } })

    serverLog.log(`ip:${userIp} cache => ${userCountry}`)
    serverLog.log(`country:${userCountry} cache => ${!!countryMapCache?.[userCountry]}`)
    // 如果有ip缓存，和国家缓存则直接用缓存
    if (countryMapCache?.[userCountry]) {
      return countryMapCache[userCountry]
    }

    const res = await getAppCenterApi()
    serverLog.log(`mapping ip:${userIp} => country:${res.countryCode}`)
    // 存储 ip => 地区 映射
    await $fetch('/api/set-ip-cache', { method: 'POST', body: { ip: userIp, country: res.countryCode } })
    // 存储 地区 => 数据 映射
    await addCountryCache(Object.assign((countryMapCache || {}), { [res.countryCode]: res }), [unref(pageKey)], 300)
    return res
  }

  return getAppCenterApi()
}, {
  getCachedData: (key, nuxt): ReturnPromiseType<typeof getAppCenterApi> => nuxt.payload.data[key] || nuxt.static.data[key],
})

whenever(error, () => {
  throw createError({ statusCode: 404, statusMessage: 'app-center has some error', fatal: true })
}, { immediate: true })

if (!isProd()) {
  whenever(data, v => clientLog.log(unref(pageKey), v), { immediate: true })
}

const { toAppType } = useRouterToPage()

const countryCode = computed(() => data.value?.countryCode)

const { autoSideBar } = useLayout()
autoSideBar()

const { appsHead } = usePageHead()
appsHead()
</script>

<template>
  <div v-track="{ id: 'web-apps', view: { value: { page: 'apps' } } }" class="view-content" pb="80px md:160px">
    <template v-if="data">
      <GameCenterHeadBanner :country-code="countryCode" :value="data.ldplayerXBanner" :banner="data.headBanner" />

      <GameGroupBlock mt="30px md:80px" content-class="bg-transparent flex flex-col gap-40px" :title="$t('game.recommend')">
        <GameCenterRecommendSwiper v-if="data.selected?.list?.length" track-type="精选推荐" :country-code="countryCode" :group="data.selected" />
        <GameCenterLiteSwiper v-if="data.recommend?.list?.length" track-type="小编推荐" :country-code="countryCode" :group="data.recommend" />
      </GameGroupBlock>

      <GameGroupBlock mt="40px md:80px" content-class="bg-transparent" :title="$t('game.hotGames')">
        <GameCenterRecommendSwiper track-type="热门游戏" :country-code="countryCode" :group="data.popularGames" />
      </GameGroupBlock>

      <div v-if="data.newGames?.list.length || data.ldLimited?.list.length" mt="40px md:80px" lt-md="flex-col" class="flex gap-40px">
        <GameGroupBlock v-if="data.newGames?.list.length" class="flex-1" :title="$t('game.newGames')">
          <div grid="~ cols-2 rows-4 auto-rows-0" class="of-hidden" lt-md="gap-[0_16px] p-16px pb-0" md="gap-[0_40px] p-24px pb-0">
            <template v-for="item in data.newGames.list" :key="item.id">
              <GameLiteSheet v-track="{ id: 'web-apps-game', click: { value: { type: '新游戏', name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" icon-class="size-60px md:size-72px" mb="16px md:24px" :name="item.name" :icon="item.iconUrl" v-bind="getStoreAppLink(item, countryCode)" />
            </template>
          </div>
        </GameGroupBlock>

        <GameGroupBlock v-if="data.ldLimited?.list.length" class="flex-1" :title="$t('game.ld_prefecture')">
          <div grid="~ cols-2 rows-4 auto-rows-0" class="of-hidden" lt-md="gap-[0_16px] p-16px pb-0" md="gap-[0_40px] p-24px pb-0">
            <template v-for="item in data.ldLimited.list" :key="item.id">
              <GameLiteSheet v-track="{ id: 'web-apps-game', click: { value: { type: '雷电专区', name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" icon-class="size-60px md:size-72px" mb="20px md:24px" :name="item.name" :icon="item.iconUrl" v-bind="getStoreAppLink(item, countryCode)" />
            </template>
          </div>
        </GameGroupBlock>
      </div>

      <template v-for="menu in (data.menus || [])" :key="menu.id">
        <GameGroupBlock mt="40px md:80px" :title="menu.name">
          <div
            class="grid of-hidden"
            lt-md="gap-[0_20px] p-20px b-0 grid-cols-4"
            md="gap-[0_32px] p-24px b-0 grid-cols-6  grid-rows-2"
            lg="grid-cols-10"
          >
            <template v-for="item in (menu.list || [])" :key="item.id">
              <GameLiteBlock v-track="{ id: 'web-apps-game', click: { value: { type: menu.name, name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" mb="20px md:24px" mode="light" :name="item.name" :icon="item.iconUrl" v-bind="getStoreAppLink(item, countryCode)" />
            </template>
          </div>
        </GameGroupBlock>
      </template>

      <template v-for="menu in (data.ldplayerXCategory || [])" :key="menu.id">
        <GameGroupBlock mt="40px md:80px" :title="menu.desc" :more="toAppType(menu.appType, { hasReturnUrl: true })">
          <div
            class="grid of-hidden"
            lt-md="gap-[0_20px] p-20px b-0 grid-cols-4"
            md="gap-[0_32px] p-24px b-0 grid-cols-6 grid-rows-2"
            lg="grid-cols-10"
          >
            <template v-for="item in (menu.apps || [])" :key="item.id">
              <GameLiteBlock v-track="{ id: 'web-apps-game', click: { value: { type: menu.desc, name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" mb="20px md:24px" mode="light" :name="item.name" :icon="item.iconUrl" v-bind="getStoreAppLink(item, countryCode)" />
            </template>
          </div>
        </GameGroupBlock>
      </template>
    </template>

    <GameGroupBlock mt="40px md:80px" content-class="bg-transparent" :title="$t('home_page.Q&A')">
      <div class="flex flex-col gap-20px">
        <GameDetailFaqGroup :q="$t('home_page.question_1')" :a="$t('home_page.answer_1')" init-visible />
        <GameDetailFaqGroup :q="$t('home_page.question_2')" :a="$t('home_page.answer_2')" />
        <GameDetailFaqGroup :q="$t('home_page.question_3')" :a="$t('home_page.answer_3')" />
      </div>
    </GameGroupBlock>
  </div>
</template>

<style lang="scss" scoped>
</style>
