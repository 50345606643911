<script setup lang="ts">
import type { StoreAppMenu } from '~/types/store'

interface Props {
  group: StoreAppMenu
  /** 广告地区 */
  countryCode: string
  /** 上报 */
  trackType: string
}

defineProps<Props>()

const swiperInstance = ref()
const swiperAttr = reactive<Record<string, any>>({
  modules: [SwiperNavigation],
  navigation: {
    disabledClass: 'pointer-events-none c-op-30',
    lockClass: 'hidden',
    prevEl: '.prev',
    nextEl: '.next',
  },
  loop: true,
  slidesPerView: 4.5,
  slidesPerGroup: 4.5,
  spaceBetween: 16,
  breakpoints: {
    1024: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 16,
    },
    1280: {
      slidesPerView: 8,
      slidesPerGroup: 8,
      spaceBetween: 16,
    },
    1536: {
      slidesPerView: 10,
      slidesPerGroup: 10,
      spaceBetween: 32,
    },
  },
})
</script>

<template>
  <div class="relative" m="x--20px" p="x-20px">
    <div class="rd-8px bg-#fff" lt-md="p-16px" md="p-24px">
      <Swiper
        class="position-initial! my--10px! py-10px!"
        :class="!swiperInstance && '[&>.swiper-wrapper]:grid [&>.swiper-wrapper]:grid-auto-rows-0 [&>.swiper-wrapper]:grid-rows-1 [&>.swiper-wrapper]:of-hidden [&>.swiper-wrapper]:grid-cols-4 [&>.swiper-wrapper]:gap-[0_16px] [&>.swiper-wrapper]:lg:grid-cols-6 [&>.swiper-wrapper]:xl:grid-cols-8 [&>.swiper-wrapper]:2xl:grid-cols-10 [&>.swiper-wrapper]:2xl:gap-[0_32px]'"
        v-bind="swiperAttr" @swiper="swiperInstance = $event"
      >
        <SwiperSlide v-for="item in (group?.list || [])" :key="item.appInfo.id">
          <GameLiteBlock
            v-track="{ id: 'web-apps-game', click: { value: { type: trackType, name: item.name, seo: item.appInfo.seoUrl, pkg: item.packageName } } }" mode="light" :name="item.name" :icon="item.iconUrl"
            v-bind="getStoreAppLink(item, countryCode)"
          />
        </SwiperSlide>

        <LdSwiperSlideButton class="prev inset-is-0 text-24px lt-md:hidden" turn="left" bg="#000 op-40" c="#fff hover:purple-5" @click="swiperInstance?.slidePrev()" />
        <LdSwiperSlideButton class="next inset-ie-0 text-24px lt-md:hidden" turn="right" bg="#000 op-40" c="#fff hover:purple-5" @click="swiperInstance?.slideNext()" />
      </Swiper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
