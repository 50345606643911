import type { StoreHome } from '~/types/store'

enum Api {
  OLD_STORE_HOME = '/store/v2/index', // 游戏中心 (拿exe的首页接口)
  STORE_HOME = '/store/naru/index', // 新版商店首页接口
  APP_MENU = '/store/v2/get_menu_games', // 某分类游戏列表
}

// /**  获取落地页模板信息 */
// export function getAppCenterApi() {
//   return useStoreHttp.get<AppMenu[]>(Api.OLD_STORE_HOME)
// }

/** 某分类游戏列表 */
export function getStoreMenuByIdApi(menuId: string) {
  return useStoreHttp.get<any>(Api.APP_MENU, { params: { menuId } })
}

/**  商店首页 */
export function getAppCenterApi() {
  return useStoreHttp.post<StoreHome>(Api.STORE_HOME, { body: { marks: { ldplayerX: true } } })
}
